@use "@jeffcarbine/premmio/public/components/loader/loader.scss";

@use "@jeffcarbine/premmio/public/components/carbineCo/carbineCoCredit";
@use "@jeffcarbine/premmio/public/components/slider/slider";
@use "@jeffcarbine/premmio/public/components/field/field";

@use "@jeffcarbine/premmio/public/components/quote/quote";

@use "@jeffcarbine/premmio/public/components/modal/modal";

@use "@jeffcarbine/premmio/public/components/user/squareCardContainer";
@use "@jeffcarbine/premmio/public/components/chip/chip";
@use "@jeffcarbine/premmio/public/components/card/card";
@use "@jeffcarbine/premmio/public/components/user/patreonAuth";
@use "@jeffcarbine/premmio/public/components/product/product";

@use "./partials/cart";
@use "./partials/tiers";
@use "./partials/shop";
// @use "./partials/rewards";

@use "@jeffcarbine/premmio/public/styles/animations";
@use "@jeffcarbine/premmio/public/components/alert/alert";
@use "@jeffcarbine/premmio/public/components/accordion/accordion";

#product {
  --product-images-background: transparent;
  --product-images-controls-color: var(--light-900);
}

.productSummaryGrid {
  margin: 3rem 0;
}

:root {
  --field-accent-color: var(--accent-500);
}

.quote {
  --quote-font: var(--serif);
  --quotation-mark-font: var(--serif);
}

.modal {
  img {
    width: 100%;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: var(--fs5);
  }
}
